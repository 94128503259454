import {format} from 'rut.js'
import {renderValidationError, renderValidationSuccess} from "../utils";

const validateCompany = () => {
  const btn = document.querySelector('#validate_company_btn');

  if (btn) {
    btn.addEventListener('click', () => {

      renderResults('init');

      const companyRut = document.querySelector('#company_rut');

      const url = 'https://api.e-laboral.com'//'https://y9c17jqtn9.execute-api.sa-east-1.amazonaws.com';
      jQuery.getJSON(`${window.location.origin}/validate/company/${format(companyRut.value, {dots: false})}`, function (data) {
        if (data.request_id) {
          const requestId = data.request_id;

          let retry = true;
          let i = 0;
          do {
            i = i + 1;
            const getStatus = async () => {
              await sleep(5000 * i);
              jQuery.getJSON(`${window.location.origin}/validate/${requestId}/status`, function (validationResult) {
                if (validationResult.status === 'EXECUTED') {
                  retry = false;
                  if (validationResult.task_summaries[0].status === 'EXECUTED_WITH_SUCCESS') {
                    const form = document.querySelector('#new_company');
                    form.submit()
                    //renderResults('ok');
                  } else if (validationResult.task_summaries[0].status === 'EXECUTED_WITH_ERROR') {
                    //renderResults('wrong');
                  } else {
                    //renderResults('wrong');
                  }
                }
              })
            };

            getStatus();
          } while (retry && i < 5000);

        } else {
          renderResults('wrong');
        }

      });

    });

  }

}

const onNewCompanySubmit = () => {
    const submitBtn = document.querySelector("#new_company_submit");

    if (submitBtn){
        submitBtn.addEventListener('click', (event) => {
          let anyError = false;

          const companyRut = document.querySelector('#company_rut');
          const companyBusinessName = document.querySelector('#company_business_name');
          const companyLineOfBusiness = document.querySelector('#company_line_of_business');
          const companyAddress = document.querySelector('#company_address');
          const companyCity = document.querySelector('#company_city');
          const companyRegion = document.querySelector('#company_region');
          const companyPhone = document.querySelector('#company_phone');
          const companyLegalRepresentativeRut = document.querySelector('#company_legal_representative_rut');
          const companyLegalRepresentativePhone = document.querySelector('#company_legal_representative_phone');
          const companyLegalRepresentativeEmail = document.querySelector('#company_legal_representative_email');
          const agreePolicy = document.querySelector('#company_agree_data_policy');

          if (companyRut.value === "") {
            renderValidationError(companyRut, 'Debe ingresar el RUT de la empresa');
            anyError = true;
          } else {
            renderValidationSuccess(companyRut)
          }

          if (companyBusinessName.value === "") {
            renderValidationError(companyBusinessName, 'Debe completar el RUT de la empresa');
            anyError = true;
          } else {
            renderValidationSuccess(companyBusinessName)
          }

          if (companyLineOfBusiness.value === "") {
            renderValidationError(companyLineOfBusiness, 'Debe seleccionar actividad de la empresa');
            anyError = true;
          } else {
            renderValidationSuccess(companyLineOfBusiness)
          }

          /*
          if (companyAddress.value === "") {
            renderValidationError(companyAddress, 'Debe ingresar la dirección de la empresa');
            anyError = true;
          } else {
            renderValidationSuccess(companyAddress)
          }

          if (companyCity.value === "") {
            renderValidationError(companyCity, 'Debe seleccionar la ciudad de la empresa');
            anyError = true;
          } else {
            renderValidationSuccess(companyCity)
          }

          if (companyRegion.value === "") {
            renderValidationError(companyRegion, 'Debe seleccionar la región de la empresa');
            anyError = true;
          } else {
            renderValidationSuccess(companyRegion)
          }

           */

          if (companyPhone.value === "") {
            renderValidationError(companyPhone, 'Debe ingresar el teléfono de la empresa');
            anyError = true;
          } else if (companyPhone.value.match(/\d/g) && companyPhone.value.match(/\d/g).length < 11) {
            renderValidationError(companyPhone, 'Debe ingresar un teléfono válido');
            anyError = true;
          } else {
            renderValidationSuccess(companyPhone)
          }

          if (companyLegalRepresentativeRut.value === "") {
            renderValidationError(companyLegalRepresentativeRut, 'Debe ingresar el RUT del representante legal');
            anyError = true;
          } else {
            renderValidationSuccess(companyLegalRepresentativeRut)
          }

          if (companyLegalRepresentativePhone.value === "") {
            renderValidationError(companyLegalRepresentativePhone, 'Debe ingresar el teléfono del representante legal');
            anyError = true;
          } else if (companyLegalRepresentativePhone.value.match(/\d/g) && companyLegalRepresentativePhone.value.match(/\d/g).length < 11) {
            renderValidationError(companyLegalRepresentativePhone, 'Debe ingresar un teléfono válido');
            anyError = true;
          } else {
            renderValidationSuccess(companyLegalRepresentativePhone)
          }

          if (companyLegalRepresentativeEmail.value.trim() !== "" && !companyLegalRepresentativeEmail.value.trim().match(/^[^@\s]+@[^@\s]+\.[^@\s]+$/)) {
            renderValidationError(companyLegalRepresentativeEmail, 'Debe ingresar un email válido');
            anyError = true;
          } else {
            renderValidationSuccess(companyLegalRepresentativeEmail)
          }

          if (!agreePolicy.checked) {
            renderValidationError(agreePolicy, 'Debe aceptar la política de datos', true);
            anyError = true;
          } else {
            renderValidationSuccess(agreePolicy)
          }

          if (anyError) { // evitamos validar la empresa si el formulario no esta completo
            event.preventDefault();
            return
          }

          // crear validacion de empresa en el automatizer
          let valid_access = false
          renderButtonResults('init');
          jQuery.getJSON(`${window.location.origin}/validate/company/${format(companyRut.value, {dots: false})}`,async function (data) {
            if (data.request_id) {
              const requestId = data.request_id;

              let retry = true;
              let i = 0;

              const getStatus = async () => {
                await sleep(5000);
                jQuery.getJSON(`${window.location.origin}/validate/${requestId}/status`, function (validationResult) {
                  if (validationResult.status === 'EXECUTED') {
                    retry = false;
                    if (validationResult.task_summaries[0].status === 'EXECUTED_WITH_SUCCESS') {
                      renderButtonResults('ok');
                      valid_access = true;
                      let company_data = JSON.parse(validationResult.task_summaries[0].additional_info[1]["value"])

                      companyAddress.value = company_data["address"]
                      companyRegion.value = company_data["region"]
                      companyCity.value = company_data["commune"]

                    } else if (validationResult.task_summaries[0].status === 'EXECUTED_WITH_ERROR') {
                      renderButtonResults('wrong', validationResult.task_summaries[0].task_errors[0].message);
                    } else {
                      renderButtonResults('wrong');
                    }
                  } else if (validationResult.status === 'EXECUTING'){
                    renderButtonResults('inProgress');
                  }
                });

                if (valid_access)
                  await sleep(30);
              };

              while (retry && i < 50) {
                await getStatus(); // Esperar a que se complete la solicitud antes de continuar

                i = i + 1;
                if (!retry ||  i > 50)
                  break
              }
              console.log("has_valid_access: " + valid_access);
              if (!valid_access) {
                  event.preventDefault();
              } else{
                const form = document.querySelector('#new_company');
                form.submit()
              }
            } else {
              renderButtonResults('wrong');
            }

          });
        });
    }
}

const sleep = (milliseconds) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
}

const renderButtonResults = (step, errorMessage = null) => {
  const spinner = document.querySelector("#validate_company_spinner");
  const okIcon = document.querySelector("#validate_company_ok");
  const xIcon = document.querySelector("#validate_company_error");
  const message = document.querySelector("#message");
  const submitBtn = document.querySelector("#new_company_submit");
  const submitBtnText = document.querySelector("#new_company_submit_text");
  const modalToggle = document.querySelector("#validation-error-modal-toggle")
  const modalBody = document.querySelector("#validation-error-body")

  if (step === 'init') {
    //submitBtn.disabled = true;
    okIcon.style.display = "none";
    xIcon.style.display = "none";

    spinner.style.display = "";

    message.classList.remove("text-success");
    message.classList.remove("text-danger");
    //message.innerHTML = "La validaci&oacute;n podr&iacute;a tomar unos minutos."

    submitBtn.disabled = true;
    submitBtnText.innerHTML = "Creando solicitud de validación"
    modalBody.innerHTML = ''

  } else if (step === 'inProgress') {
    //submitBtn.disabled = true;
    okIcon.style.display = "none";
    xIcon.style.display = "none";

    spinner.style.display = "";

    message.classList.remove("text-success");
    message.classList.remove("text-danger");
    //message.innerHTML = "La validaci&oacute;n podr&iacute;a tomar unos minutos."

    submitBtn.disabled = true;
    submitBtnText.innerHTML = "Ejecutando solicitud de validaci&oacute;n"
  } else if (step === 'ok') {
    spinner.style.display = "";
    okIcon.style.display = "none";

    //message.innerHTML = "Validaci&oacute;n correcta."
    message.classList.add("text-success");

    submitBtn.disabled = true;
    submitBtnText.innerHTML = "Validación exitosa, guardando"
  } else if (step === 'wrong') {
    spinner.style.display = "none";
    xIcon.style.display = "none";

    if (modalBody.innerHTML === '')
      modalToggle.click()

    if (errorMessage !== null && errorMessage === 'Error, no se pudo autenticar con las credenciales proporcionadas.'){
      modalBody.innerHTML = 'Error, no se pudo autenticar con las credenciales proporcionadas. <br><br> ' +
          'Porfavor reconfigure su clave unica con la ayuda del asistente inicial que se encuentra en las opciones que se despliega sobre su nombre de usuario en la esquina superior derecha.'
    } else if (errorMessage !== null && errorMessage === 'Error, Usuario de clave unica bloqueado, compruebe el acceso de usuario en el portal de la DT.'){
      modalBody.innerHTML = "Error, su usuario de clave unica se encuentra bloqueado, por favor compruebe el acceso en el portal de la DT y recupere el acceso a su cuenta de clave unica. <br> <br>" +
          "Cuando haya recuperado su cuenta, por favor utilice el asistente inicial para re-configurar su clave unica. El asistente inicial que se encuentra en las opciones que se despliega sobre su nombre de usuario en la esquina superior derecha"
    } else {
      modalBody.innerHTML = errorMessage == null ? "Validaci&oacute;n incorrecta." : errorMessage
    }

    submitBtn.disabled = false;
    submitBtnText.innerHTML = "Validar y crear empresa"
  }
};


const renderResults = (step) => {
  const spinner = document.querySelector("#validate_company_spinner");
  const okIcon = document.querySelector("#validate_company_ok");
  const xIcon = document.querySelector("#validate_company_error");
  const message = document.querySelector("#message");
  const submitBtn = document.querySelector(".submit_btn");

  if (step === 'init') {
    //submitBtn.disabled = true;
    okIcon.style.display = "none";
    xIcon.style.display = "none";
    spinner.style.display = "";
    message.classList.remove("text-success");
    message.classList.remove("text-danger");
    message.innerHTML = "La validaci&oacute;n podr&iacute;a tomar unos minutos."

  } else if (step === 'ok') {
    spinner.style.display = "none";
    okIcon.style.display = "";
    //submitBtn.disabled = false;
    message.innerHTML = "Validaci&oacute;n correcta."
    message.classList.add("text-success");

  } else if (step === 'wrong') {
    spinner.style.display = "none";
    xIcon.style.display = "";
    //submitBtn.disabled = true;
    message.innerHTML = "Validaci&oacute;n incorrecta."
    message.classList.add("text-danger");
  }
};

export { validateCompany, onNewCompanySubmit };
