export const onCompanyInfoCollapse = () => {
    const companyCards = document.querySelectorAll(".company-info-card");

    if (companyCards)
        companyCards.forEach(function(card) {
            const toggleButton = card.querySelector(".company-info-toggle");
            const collapseContent = card.querySelector(".collapse");

            toggleButton.addEventListener("click", function() {
                collapseContent.classList.toggle("show");
            });
        });
}


export const onShowCompaniesHiddenElements = () => {
    const showHiddenContractSet = document.getElementById("show-hidden-contract-set");
    const showHiddenAttachments = document.getElementById("show-hidden-attachments");
    const showHiddenEndings = document.getElementById("show-hidden-endings");

    if (showHiddenContractSet){
        const showHiddenElements =  [
            [showHiddenContractSet, "?show_hidden_contract_set="],
            [showHiddenAttachments, "?show_hidden_attachments="],
            [showHiddenEndings, "?show_hidden_endings="]
        ]
        for (const [el, query] of showHiddenElements){
            el.addEventListener("change", function (e){
                let url = window.location.origin
                url += window.location.pathname
                url += query + e.target.checked

                window.location = url
            })
        }

    }
}