export const renderValidationError = (input, message, is_switch = false) => {
    const span = document.createElement('span'); // Crea un nuevo elemento span
    span.textContent = message; // Establece el texto del span
    span.id = input.id + '_error'; // Establece el id del span

    try {
        input.parentNode.removeChild(document.querySelector('#' + span.id));
    } catch (error) {}
    
    if (!is_switch) {
        span.className = 'invalid-feedback'; // Agrega una clase CSS al span
        input.classList.add('is-invalid'); // Agrega una clase CSS al input
        input.classList.remove('is-valid');
    } else {
        span.className = 'invalid-feedback d-block';
    }


    input.parentNode.appendChild(span);
}

export const renderValidationSuccess = (input) => {
    try {
        input.parentNode.removeChild(document.querySelector('#' + input.id + '_error'));
    } catch (error) {}

    input.classList.remove('is-invalid');
    input.classList.add('is-valid');
}