import {renderValidationError, renderValidationSuccess} from "../utils";

export const onSubmitContractEndUploadFormFileUpload = () => {
    const submitBtn = document.querySelector('#contract_end_upload_file_upload_submit');

    if (submitBtn) {
        submitBtn.addEventListener('click', (event) => {
            let anyError = false;

            const contractEndUploadName = document.querySelector('#contract-upload-name')
            const contractEndUploadDescription = document.querySelector('#contract-upload-description')
            const contractEndUploadFile = document.querySelector('#file_input')

            if (contractEndUploadName.value.trim() === "") {
                renderValidationError(contractEndUploadName, "Debe ingresar un nombre")
                anyError = true;
            } else {
                renderValidationSuccess(contractEndUploadName)
            }

            if (contractEndUploadDescription.value.trim() === "") {
                renderValidationError(contractEndUploadDescription, "Debe ingresar una descripción")
                anyError = true;
            } else {
                renderValidationSuccess(contractEndUploadDescription)
            }

            if (contractEndUploadFile.files.length === 0) {
                renderValidationError(contractEndUploadFile, "Debe seleccionar un archivo.")
                anyError = true;
            } else {
                renderValidationSuccess(contractEndUploadFile)
            }

            if (anyError) {
                event.preventDefault();
            }
        });
    }
}