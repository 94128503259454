import {renderValidationError, renderValidationSuccess} from "../utils";

export const onSubmitContractAttachmentUploadFormFileUpload = () => {
    const submitBtn = document.querySelector('#contract_attachment_upload_file_upload_submit');

    if (submitBtn) {
        submitBtn.addEventListener('click', (event) => {
            let anyError = false;

            const contractAttachmentUploadName = document.querySelector('#contract-upload-name')
            const contractAttachmentUploadDescription = document.querySelector('#contract-upload-description')
            const contractAttachmentUploadFile = document.querySelector('#file_input')

            if (contractAttachmentUploadName.value.trim() === "") {
                renderValidationError(contractAttachmentUploadName, "Debe ingresar un nombre")
                anyError = true;
            } else {
                renderValidationSuccess(contractAttachmentUploadName)
            }

            if (contractAttachmentUploadDescription.value.trim() === "") {
                renderValidationError(contractAttachmentUploadDescription, "Debe ingresar una descripción")
                anyError = true;
            } else {
                renderValidationSuccess(contractAttachmentUploadDescription)
            }

            if (contractAttachmentUploadFile.files.length === 0) {
                renderValidationError(contractAttachmentUploadFile, "Debe seleccionar un archivo.")
                anyError = true;
            } else {
                renderValidationSuccess(contractAttachmentUploadFile)
            }

            if (anyError) {
                event.preventDefault();
            }
        });
    }
}