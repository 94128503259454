import $ from "jquery";
import {renderValidationError, renderValidationSuccess} from "../utils";
import Inputmask from "inputmask";
import { validate, clean, format, getCheckDigit } from 'rut.js'

const validateCompanies = () => {
    $(document).ready(function () {
        $('[name*="company"][name*="[rut]"]').rut({
            formatOn: 'keyup',
            minimumLength: 8,
            validateOn: 'change keyup'
        }).on('rutInvalido', function () {
            $(this).removeClass("is-invalid");
            $(this).addClass("is-invalid");
            resetAutocompleteDynamic(this.name.split("[")[0]);
        })
            .on('rutValido', function (e, rut, dv) {
                $(this).removeClass("is-invalid");
                $(this).addClass("is-valid");
                autocompleteDynamic(this.name.split("[")[0], `${rut}-${dv}`);
            });

        const companyPhone = new Inputmask("(+99) 9 9999 9999", {
            //placeholder: "(+56) 9 1234 5678",
            //clearIncomplete: true,
            removeMaskOnSubmit: true
        }).mask($('[name*="company"][name*="[phone]"]'));

        const legalRepresentativePhone = new Inputmask("(+99) 9 9999 9999", {
            //placeholder: "(+56) 9 1234 5678",
            clearIncomplete: false,
            removeMaskOnSubmit: true
        }).mask($('[name*="company"][name*="[legal_representative_phone]"]'));

        const autocompleteDynamic = (company_id, rutAndDV) => {
            jQuery.getJSON(`${window.location.origin}/consulta_sii`, { rut: rutAndDV }, function (result) {
                $(`[name="${company_id}[business_name]"]`).val(result['razon_social']);
            });
        }

        const resetAutocompleteDynamic = (company_id) => {
            $(`[name="${company_id}[business_name]"]`).val("");
        }

        $("#companies_upload").submit(function(e){
            let count = 0
            let anyError = false
            if (e.originalEvent.submitter.name === "remove")
                return true

            while (true) {
                if($('#company-' + count).length === 0)
                    break

                if (validateCompanyForm(count) === true)
                    anyError = true

                count += 1
            }

            return !anyError
        });

        $(document).on('change', '.file-input', function() {


            var filesCount = $(this)[0].files.length;

            var textbox = $(this).prev();

            if (filesCount === 1) {
                var fileName = $(this).val().split('\\').pop();
                textbox.text(fileName);
            } else {
                textbox.text(filesCount + ' files selected');
            }
        });

        // recarga automatica si existe una fila con estado "VALIDANDO"
        const refreshCompaniesResult = document.querySelector(`#reload_results`);
        if (refreshCompaniesResult){
            const result_table = document.querySelector(`#company_validation_results`);
            let reload = false

            for (let r of result_table.rows){
                if (r.cells[2].textContent.toLowerCase().trim() === "validando")
                    reload = true
            }

            if (reload)
                setTimeout(function () {
                    refreshCompaniesResult.click()
                }, 5 * 1000);
        }

    });



}


const validateCompanyForm = (company_index) => {
    let  anyError = false
    const companyRut = document.querySelector(`[name="company-${company_index}[rut]"]`);
    const companyBusinessName = document.querySelector(`[name="company-${company_index}[business_name]"]`);
    const companyPhone = document.querySelector(`[name="company-${company_index}[phone]"]`);
    const companyLegalRepresentativeRut = document.querySelector(`[name="company-${company_index}[legal_representative_rut]"]`);
    const companyLegalRepresentativePhone = document.querySelector(`[name="company-${company_index}[legal_representative_phone]"]`);
    const companyLegalRepresentativeEmail = document.querySelector(`[name="company-${company_index}[legal_representative_email]"]`);

    if (companyRut.value === "") {
        renderValidationError(companyRut, 'Debe ingresar el RUT de la empresa');
        anyError = true;
    } else if (!validate(companyRut.value)) {
        renderValidationError(companyRut, 'Debe ingresar un RUT válido');
        anyError = true;
    }else {
        renderValidationSuccess(companyRut)
    }

    if (companyBusinessName.value === "") {
        renderValidationError(companyBusinessName, 'Debe completar el RUT de la empresa');
        anyError = true;
    } else {
        renderValidationSuccess(companyBusinessName)
    }

    if (companyPhone.value === "") {
        renderValidationError(companyPhone, 'Debe ingresar el teléfono de la empresa');
        anyError = true;
    } else if (companyPhone.value.match(/\d/g) && companyPhone.value.match(/\d/g).length < 11) {
        renderValidationError(companyPhone, 'Debe ingresar un teléfono válido');
        anyError = true;
    } else {
        renderValidationSuccess(companyPhone)
    }

    if (companyLegalRepresentativeRut.value === "") {
        renderValidationError(companyLegalRepresentativeRut, 'Debe ingresar el RUT del representante legal');
        anyError = true;
    } else if (!validate(companyLegalRepresentativeRut.value)) {
        renderValidationError(companyLegalRepresentativeRut, 'Debe ingresar un RUT válido');
        anyError = true;
    } else {
        renderValidationSuccess(companyLegalRepresentativeRut)
    }

    if (companyLegalRepresentativePhone.value === "") {
        renderValidationError(companyLegalRepresentativePhone, 'Debe ingresar el teléfono del representante legal');
        anyError = true;
    } else if (companyLegalRepresentativePhone.value.match(/\d/g) && companyLegalRepresentativePhone.value.match(/\d/g).length < 11) {
        renderValidationError(companyLegalRepresentativePhone, 'Debe ingresar un teléfono válido');
        anyError = true;
    } else {
        renderValidationSuccess(companyLegalRepresentativePhone)
    }

    if (companyLegalRepresentativeEmail.value.trim() !== "" && !companyLegalRepresentativeEmail.value.trim().match(/^[^@\s]+@[^@\s]+\.[^@\s]+$/)) {
        renderValidationError(companyLegalRepresentativeEmail, 'Debe ingresar un email válido');
        anyError = true;
    } else {
        renderValidationSuccess(companyLegalRepresentativeEmail)
    }

    return anyError
}

export {validateCompanies}