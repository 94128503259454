import {renderValidationError, renderValidationSuccess} from "../utils";

export const onSubmitContractUploadFormFileUpload = () => {
    const submitBtn = document.querySelector('#contract_upload_file_upload_submit');

    if (submitBtn) {
        submitBtn.addEventListener('click', (event) => {
            let anyError = false;

            const contractUploadName = document.querySelector('#contract-upload-name')
            const contractUploadDescription = document.querySelector('#contract-upload-description')
            const contractUploadFile = document.querySelector('#file_input')

            if (contractUploadName.value.trim() === "") {
                renderValidationError(contractUploadName, "Debe ingresar un nombre")
                anyError = true;
            } else {
                renderValidationSuccess(contractUploadName)
            }

            if (contractUploadDescription.value.trim() === "") {
                renderValidationError(contractUploadDescription, "Debe ingresar una descripción")
                anyError = true;
            } else {
                renderValidationSuccess(contractUploadDescription)
            }

            if (contractUploadFile.files.length === 0) {
                renderValidationError(contractUploadFile, "Debe seleccionar un archivo.")
                anyError = true;
            } else {
                renderValidationSuccess(contractUploadFile)
            }

            if (anyError) {
                event.preventDefault();
            }
        });
    }
}