import {renderValidationError, renderValidationSuccess} from "../utils";

export const onSubmitAttachment = () => {
    const submitBtn = document.querySelector('#attachment_submit');

    if (submitBtn) {
        submitBtn.addEventListener('click', (event) => {
            let anyError = false;

            const attachmentCompany = document.querySelector('#attachment_company')
            const attachmentName = document.querySelector('#attachment_name')
            const attachmentDescription = document.querySelector('#attachment_description')
            const attachmentSpreadsheet = document.querySelector('#attachment_spreadsheet')

            if (attachmentCompany.value.trim() === "") {
                renderValidationError(attachmentCompany, "Debe seleccionar una empresa.")
                anyError = true;
            } else {
                renderValidationSuccess(attachmentCompany)
            }

            if (attachmentName.value.trim() === "") {
                renderValidationError(attachmentName, "Debe ingresar un nombre.")
                anyError = true;
            } else {
                renderValidationSuccess(attachmentName)
            }

            if (attachmentDescription.value.trim() === "") {
                renderValidationError(attachmentDescription, "Debe ingresar una descripción.")
                anyError = true;
            } else {
                renderValidationSuccess(attachmentDescription)
            }

            if (attachmentSpreadsheet.files.length === 0) {
                renderValidationError(attachmentSpreadsheet, "Debe seleccionar un archivo.")
                anyError = true;
            } else {
                renderValidationSuccess(attachmentSpreadsheet)
            }

            if (anyError) {
                event.preventDefault();
            }
        });
    }
}