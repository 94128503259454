import {renderValidationError, renderValidationSuccess} from "../utils";

export const onSubmitEnding = () => {
    const submitBtn = document.querySelector('#ending_submit');

    if (submitBtn) {
        submitBtn.addEventListener('click', (event) => {
            let anyError = false;

            const endingCompany = document.querySelector('#ending_company')
            const endingName = document.querySelector('#ending_name')
            const endingDescription = document.querySelector('#ending_description')
            const endingSpreadsheet = document.querySelector('#ending_spreadsheet')

            if (endingCompany.value.trim() === "") {
                renderValidationError(endingCompany, "Debe seleccionar una empresa.")
                anyError = true;
            } else {
                renderValidationSuccess(endingCompany)
            }

            if (endingName.value.trim() === "") {
                renderValidationError(endingName, "Debe ingresar un nombre.")
                anyError = true;
            } else {
                renderValidationSuccess(endingName)
            }

            if (endingDescription.value.trim() === "") {
                renderValidationError(endingDescription, "Debe ingresar una descripción.")
                anyError = true;
            } else {
                renderValidationSuccess(endingDescription)
            }

            if (endingSpreadsheet.files.length === 0) {
                renderValidationError(endingSpreadsheet, "Debe seleccionar un archivo.")
                anyError = true;
            } else {
                renderValidationSuccess(endingSpreadsheet)
            }

            if (anyError) {
                event.preventDefault();
            }
        });
    }
}