import {renderValidationError, renderValidationSuccess} from "../utils";


export const onSubmitPersonalInfo = () => {
    const submitBtn = document.querySelector('#personal_info_submit');

    if (submitBtn) {
        submitBtn.addEventListener('click', (event) => {
            console.log('submitting')
            let anyError = false;

            const userName = document.querySelector('#user_first_name');
            const userSurname = document.querySelector('#user_first_surname');
            const userSecondSurname = document.querySelector('#user_second_surname');
            const userEmail = document.querySelector('#user_email');
            const userRut = document.querySelector('#user_rut');
            const userPhone = document.querySelector('#user_phone');
            const agreePolicy = document.querySelector('#user_agree_data_policy');

            if (userName.value.trim() === "") {
                renderValidationError(userName, 'Debe ingresar su nombre');
                anyError = true;
            } else {
                renderValidationSuccess(userName)
            }

            if (userSurname.value.trim() === "") {
                renderValidationError(userSurname, 'Debe ingresar su apellido paterno');
                anyError = true;
            } else {
                renderValidationSuccess(userSurname)
            }

            if (userSecondSurname.value.trim() === "") {
                renderValidationError(userSecondSurname, 'Debe ingresar su apellido materno');
                anyError = true;
            } else {
                renderValidationSuccess(userSecondSurname)
            }

            if (userEmail.value.trim() === "") {
                renderValidationError(userEmail, 'Debe ingresar su correo electrónico');
                anyError = true;
            } else if (!userEmail.value.trim().match(/^[^@\s]+@[^@\s]+\.[^@\s]+$/)){
                renderValidationError(userEmail, 'Debe ingresar un correo electrónico válido');
                anyError = true;
            }
            else {
                renderValidationSuccess(userEmail)
            }

            if (userRut.value.trim() === "") {
                renderValidationError(userRut, 'Debe ingresar su RUT');
                anyError = true;
            } else {
                renderValidationSuccess(userRut)
            }

            if (userPhone.value.trim() === "") {
                renderValidationError(userPhone, 'Debe ingresar su teléfono');
                anyError = true;
            } else if (userPhone.value.trim().match(/\d/g) && userPhone.value.trim().match(/\d/g).length < 11) {
                renderValidationError(userPhone, 'Debe ingresar un teléfono válido');
                anyError = true;
            } else {
                renderValidationSuccess(userPhone)
            }

            if (!agreePolicy.checked) {
                renderValidationError(agreePolicy, 'Debe aceptar la política de datos', true);
                anyError = true;
            } else {
                renderValidationSuccess(agreePolicy)
            }

            if (anyError) {
                event.preventDefault();
            }
        });
    }
}