import {renderValidationError, renderValidationSuccess} from "../utils";

export const onSubmitScheduledAction = () => {
    const submitBtn = document.querySelector('#schedule_action_submit');

    if (submitBtn) {
        submitBtn.addEventListener('click', (event) => {
            let anyError = false;

            const activity = document.getElementById('activity');
            const actionType = document.querySelector('#actionType')
            const scheduledAt = document.querySelector('#scheduledAt')
            const scheduleNow = document.querySelector('#scheduleNow')
            console.log(scheduleNow.checked)
            console.log(activity)

            if (actionType.value.trim() === "" || actionType.value.trim() === "Seleccione...") {
                renderValidationError(actionType, "Debe de seleccionar una acción a realizar.")
                anyError = true;
            } else {
                renderValidationSuccess(actionType)
            }

            if (!scheduleNow.checked && scheduledAt.value.trim() === "") {
                renderValidationError(scheduledAt, "Debe de indicar una fecha y hora de ejecución.")
                anyError = true;
            } else {
                renderValidationSuccess(scheduledAt)
            }

            if (anyError) {
                event.preventDefault();
            }
        });
    }

    let actionLinks = document.querySelectorAll(".configure-action");
    actionLinks.forEach(function(elem) {
        const hiddenInput = document.getElementById('activity');
        elem.addEventListener('click', function() {
            hiddenInput.value = this.getAttribute('activity-id');
        });
    });


    let showDetail = document.querySelectorAll(".detail-modal");
    showDetail.forEach(function(elem) {
        const action = document.getElementById('scheduled_action');
        const time = document.getElementById('scheduled_time');
        const isDispatched = document.getElementById('scheduled_dispatched');
        const scheduledAt = document.getElementById('scheduled_at');

        elem.addEventListener('click', function() {
            const data = JSON.parse(this.getAttribute('bs-modal-data'))

            if (data["action"] === "TERMINATE")
                action.innerText = "Terminar contrato"
            else if (data["action"] === "EXTEND")
                action.innerText = "Extender contrato"
            else if (data["action"] === "NOTHING")
                action.innerText = "Nada"

            if (data["at"] != null)
                time.innerText = new Date(data["at"]).toLocaleString().replace(",", "")
            else
                time.innerText = "-"

            isDispatched.innerText = data["dispatched"] ? "Si" : "No";
            scheduledAt.innerText = new Date(data["createdAt"]).toLocaleString().replace(",", "")
        });
    });

}

