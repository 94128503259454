export const onCompanySyncing = () => {
    const record_details_table = document.querySelector('#companies_records_detail');
    if (record_details_table){
        setTimeout(() => {
            let syncing = false
            for (let i = 0; i < record_details_table.rows.length; i++) {

                const celdas = record_details_table.rows[i].cells;
                if(!celdas[2].innerText.toUpperCase().includes("SINCRONIZANDO"))
                    continue

                jQuery.get(`${window.location.origin}/company/contract/history_fetch/status?company_rut=${celdas[1].innerText}`,
                    function (data) {})
                syncing = true
            }

            if (syncing)
                location.reload();
        }, 10000);
    }

    const company_rut = document.querySelector('#company_rut');
    const sync_state = document.querySelector('#sync_state');

    if (company_rut && sync_state){
        setTimeout(() => {
            if(!sync_state.innerText.toUpperCase().includes("SINCRONIZANDO"))
                return

            jQuery.get(`${window.location.origin}/company/contract/history_fetch/status?company_rut=${company_rut.innerText}`,
                function (data) {})

            location.reload();
        }, 5000);

    }


}