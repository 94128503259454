import {renderValidationError, renderValidationSuccess} from "../utils";


export const onSubmitUniqueKey = () => {
    const submitBtn = document.querySelector('#unique_key_submit');

    if (submitBtn) {
        submitBtn.addEventListener('click', (event) => {
            let anyError = false;

            const userRut = document.querySelector('#user_rut');
            const userUniqueKey = document.querySelector('#user_unique_key');
            const message = document.querySelector("#message");
            const agreePolicy = document.querySelector('#user_agree_terms');
            const userAgreeUniqueKey = document.querySelector('#user_agree_unique_key');
            const userAgreeSecurity = document.querySelector('#user_agree_security_policy');

            if (userRut.value.trim() === "") {
                renderValidationError(userRut, 'Debe ingresar su RUT');
                anyError = true;
            } else {
                renderValidationSuccess(userRut)
            }

            if (userUniqueKey.value.trim() === "") {
                renderValidationError(userUniqueKey, 'Debe ingresar su clave única');
                anyError = true;
            } else {
                renderValidationSuccess(userUniqueKey)
            }

            if (!agreePolicy.checked) {
                renderValidationError(agreePolicy, 'Debe aceptar la política de datos', true);
                anyError = true;
            } else {
                renderValidationSuccess(agreePolicy)
            }

            if (!userAgreeUniqueKey.checked) {
                renderValidationError(userAgreeUniqueKey, 'Debe aceptar la política de datos', true);
                anyError = true;
            } else {
                renderValidationSuccess(userAgreeUniqueKey)
            }

            if (!userAgreeSecurity.checked) {
                renderValidationError(userAgreeSecurity, 'Debe aceptar la política de datos', true);
                anyError = true;
            } else {
                renderValidationSuccess(userAgreeSecurity)
            }

            if (message.textContent.trim() === "" || message.textContent.trim() !== "Validación correcta.") {
                anyError = true;
                message.classList.add("text-danger");
                message.innerHTML = "Debe validar su clave única."
            }

            if (anyError) {
                event.preventDefault();
            }
        });
    }
}