import $ from "jquery";
import {renderValidationError, renderValidationSuccess} from "../utils";


function isValidBase64(base64String) {
    const base64Content = base64String.replace(/^data:text\/csv;base64,/, '');
    const decodedContent = atob(base64Content);
    return decodedContent.trim().length > 0;
}

function isValidDate(year, month) {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1

    if (year > currentYear) {
        return false;
    } else if (year === currentYear && month > currentMonth) {
        return false;
    }
    return true;
}

const months = {
    "ENERO": 1,
    "FEBRERO": 2,
    "MARZO": 3,
    "ABRIL": 4,
    "MAYO": 5,
    "JUNIO": 6,
    "JULIO": 7,
    "AGOSTO": 8,
    "SEPTIEMBRE": 9,
    "OCTUBRE": 10,
    "NOVIEMBRE": 11,
    "DICIEMBRE": 12
};

const hasDuplicatedStatements = (index, accum) => {
    const companyRut = document.querySelector(`[name="remuneration-${index}[company_rut]"]`);
    const statementMonth = document.querySelector(`[name="remuneration-${index}[statements_month]"]`);
    const statementYear = document.querySelector(`[name="remuneration-${index}[statements_year]"]`);

    let duplicated = false
    if (accum.some((e) => e.companyRut === companyRut.value && e.statementMonth === statementMonth.value && e.statementYear === statementYear.value )) {
        duplicated = true;
        renderValidationError(statementMonth, 'No puede incluir año y mes duplicados.');
    }

    accum.push({companyRut: companyRut.value, statementMonth: statementMonth.value, statementYear: statementYear.value});

    return duplicated
}


const validateRemunerationBooks = () => {
    $(document).ready(function () {
        $("#remuneration_books_upload").submit(function(e){
            let count = 0
            let anyError = false
            if (e.originalEvent.submitter.name === "remove")
                return true

            let accum_statements = []
            while (true) {
                if($('#remuneration-' + count).length === 0)
                    break

                if (validateRemunerationBooksUploadForm(count) === true)
                    anyError = true

                if (hasDuplicatedStatements(count, accum_statements))
                    anyError = true

                count += 1
            }

            return !anyError
        });

        $("button[name='remove']").click(function (e){
            $(`#remuneration-${e.currentTarget.value}`).remove()
        })

        $(document).on('change', '.file-input', function() {
            var filesCount = $(this)[0].files.length;

            var textbox = $(this).prev();

            if (filesCount === 1) {
                var fileName = $(this).val().split('\\').pop();
                textbox.text(fileName);
            } else {
                textbox.text(filesCount + ' archivos seleccionados');
            }
        });
    });
}

const validateRemunerationBooksUploadForm = (index) => {
    let  anyError = false

    const companyRut = document.querySelector(`[name="remuneration-${index}[company_rut]"]`);
    const statementMonth = document.querySelector(`[name="remuneration-${index}[statements_month]"]`);
    const statementYear = document.querySelector(`[name="remuneration-${index}[statements_year]"]`);
    const csvContent = document.querySelector(`[name="remuneration-${index}[file_content]"]`);

    if (companyRut.value === "") {
        renderValidationError(companyRut, 'Debe de seleccionar una empresa');
        anyError = true;
    }else {
        renderValidationSuccess(companyRut)
    }

    if (statementMonth.value === "") {
        renderValidationError(statementMonth, 'Debe de indicar un mes');
        anyError = true;
    } else {
        renderValidationSuccess(statementMonth)
    }

    if (statementYear.value === "") {
        renderValidationError(statementYear, 'Debe indicar un año');
        anyError = true;

    } else if (isValidDate(parseInt(statementYear.value), months[statementMonth.value]) === false){
        renderValidationError(statementYear, 'El mes y año no puede superar el actual');
        anyError = true
    } else {
        renderValidationSuccess(statementYear)
    }

    if (isValidBase64(csvContent.value) === false) {
        renderValidationError(csvContent, 'El archivo cargado no puede estar vacio');
        anyError = true;
    }else {
        renderValidationSuccess(csvContent)
    }
    
    return anyError
}

export {validateRemunerationBooks}