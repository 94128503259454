import {renderValidationError, renderValidationSuccess} from "../utils";

export const onSubmitContractSet = () => {
    const submitBtn = document.querySelector('#contract_set_submit');

    if (submitBtn) {
        submitBtn.addEventListener('click', (event) => {
            let anyError = false;

            const contractsSetCompany = document.querySelector('#contracts_set_company')
            const contractsSetName = document.querySelector('#contracts_set_name')
            const contractsSetDescription = document.querySelector('#contracts_set_description')
            const contractsSetSpreadsheet = document.querySelector('#contracts_set_spreadsheet')

            if (contractsSetCompany.value.trim() === "") {
                renderValidationError(contractsSetCompany, "Debe seleccionar una empresa.")
                anyError = true;
            } else {
                renderValidationSuccess(contractsSetCompany)
            }

            if (contractsSetName.value.trim() === "") {
                renderValidationError(contractsSetName, "Debe ingresar un nombre.")
                anyError = true;
            } else {
                renderValidationSuccess(contractsSetName)
            }

            if (contractsSetDescription.value.trim() === "") {
                renderValidationError(contractsSetDescription, "Debe ingresar una descripción.")
                anyError = true;
            } else {
                renderValidationSuccess(contractsSetDescription)
            }

            if (contractsSetSpreadsheet.files.length === 0) {
                renderValidationError(contractsSetSpreadsheet, "Debe seleccionar un archivo.")
                anyError = true;
            } else {
                renderValidationSuccess(contractsSetSpreadsheet)
            }

            if (anyError) {
                event.preventDefault();
            }
        });
    }
}