import {format} from "rut.js";

const recordReview = () => {

  const firstRecRevIcons = document.querySelectorAll('.first_record_review');
  if (firstRecRevIcons) {
    firstRecRevIcons.forEach((btn) => {
      btn.addEventListener('click', (event) => {
        const userRut = event.currentTarget.dataset.userrut;
        const companyRut = event.currentTarget.dataset.companyrut;
        const companyId = event.currentTarget.dataset.companyid;
       //showHideSpinner(companyRut, 'show');
        //postToApi(userRut, companyRut);
        //setTimeout(getToApi, 5000, companyRut, companyId);
       // setTimeout(showHideSpinner, 5000, companyRut, 'hide');
      });
    });
  }

  const updateRecRevIcons = document.querySelectorAll('.update_record_review');
  if (updateRecRevIcons) {
    updateRecRevIcons.forEach((btn) => {
      btn.addEventListener('click', (event) => {
        const companyRut = event.currentTarget.dataset.companyrut;
        const companyId = event.currentTarget.dataset.companyid;
        showHideSpinner(companyRut, 'show');
        getToApi(companyRut, companyId);
        setTimeout(showHideSpinner, 500, companyRut, 'hide');
      });
    });

  }

  const postToApi = (userRut, companyRut) => {
    jQuery.getJSON(`${window.location.origin}/company/contract/history/${format(companyRut, {dots: false})}`, function (data) {
      console.log(data);
    });
  }

  // cron job to get data every day at 00:00
  //

  const getToApi = (companyRut, companyId) => {
    jQuery.getJSON(`${window.location.origin}/company/contract/history/${format(companyRut, {dots: false})}/json`, function (data) {
      console.log(data)
      renderRecords(companyRut, data);
      saveInDB(companyId, data);
    });
  }
  const saveInDB = (companyId, data) => {
    // console.log(companyId, data);
    if (data.length > 0) {
      const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
      fetch(`${window.location.origin}/companies/${companyId}/update_records`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken,
        },
        body: JSON.stringify({ api_records_data: data }),
      })
        .then(response => response.json())
        .then(data => {
          // console.log('Success: ', data);
        })
        .catch(error => {
          console.log('Error: ', error);
        });
    }
  }


  const renderRecords = (companyRut, data) => {
    const lastUpdate = document.getElementById(`${companyRut}_last_update`);
    const valid = document.getElementById(`${companyRut}_valid`);
    const notValid = document.getElementById(`${companyRut}_not_valid`);
    const finished = document.getElementById(`${companyRut}_finished`);
    const deleted = document.getElementById(`${companyRut}_deleted`);

    if (data.length > 0) {
      lastUpdate.innerHTML = data[0].last_update;

      // Sort by rut and version; last version first
      const dataSorted = data.sort((a, b) => {
          if (a.rut === b.rut) {
            return b.version > a.version ? 1 : -1;
          }
          return a.rut > b.rut ? 1 : -1;
        });
      // console.log('sorted:', dataSorted);

      // Get all ruts and remove duplicated
      const allRuts = dataSorted.map(item =>  item.rut);
      // console.log(allRuts);
      const nonDuplicatedRuts = [...new Set(allRuts)];
      // console.log(nonDuplicatedRuts);
      // For each non duplicated rut, get a data array and then get an array with the last version of each one
      const lastVersionData = allRuts;
      nonDuplicatedRuts.forEach((rut) => {
        lastVersionData.push(dataSorted.filter(item => item.rut === rut)[0]);
      })
      // console.log('lastVersionData: ', lastVersionData)

      // Count by validity
      valid.innerHTML = dataSorted.filter(item => item.validity === 'Vigente').length;
      notValid.innerHTML = dataSorted.filter(item => item.validity === 'No Vigente').length;
      finished.innerHTML = dataSorted.filter(item => item.validity === 'Terminado').length;
      deleted.innerHTML = dataSorted.filter(item => item.validity === 'Eliminado').length;
    } else {
      lastUpdate.innerHTML = 'Sin informaci&oacute;n';
      valid.innerHTML = 0;
      notValid.innerHTML = 0;
      finished.innerHTML = 0;
      deleted.innerHTML = 0;
    }

  }

  const showHideSpinner = (companyRut, action) => {
    const spinner = document.getElementById(`${companyRut}_spinner`);
    // console.log(companyRut, action, spinner);
    if (action === 'show') {
      spinner.style.display = '';
    } else if (action === 'hide') {
      spinner.style.display = 'none';
    }
  }


}

export { recordReview };
