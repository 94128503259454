
export const preferences = () => {

    // Configurar visualizacion input según el valor del checkbox
    if ($('#enable-attachment-purge').is(':checked')) {
        $('#attachment_purge_interval_input').removeClass("d-none")
        $('#attachment_purge_interval_input').addClass("d-block")
    } else {
        $('#attachment_purge_interval_input').removeClass("d-block")
        $('#attachment_purge_interval_input').addClass("d-none")
    }

    $('#enable-attachment-purge').change(function() {
        if ($(this).is(':checked')) {
            $('#attachment_purge_interval_input').removeClass("d-none")
            $('#attachment_purge_interval_input').addClass("d-block")
        }else{
            $('#attachment_purge_interval_input').removeClass("d-block")
            $('#attachment_purge_interval_input').addClass("d-none")
            $('#attachment_purge_interval').val('');
        }
    });

}