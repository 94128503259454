// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

require("@popperjs/core")

//import "bootstrap"
//import { Tooltip, Popover } from "bootstrap"

require("../stylesheets/application.scss")

import "chartkick/chart.js"

// Silicon Theme
import 'assets/vendor/bootstrap/dist/js/bootstrap'
import Popover from "../../../vendor/assets/vendor/bootstrap/js/src/popover"
import Tooltip from "../../../vendor/assets/vendor/bootstrap/js/src/tooltip"
import 'assets/javascripts/theme.js'
import 'assets/stylesheets/theme.scss'

import { passwordStrength } from '../components/password_strength';
import { validations } from '../components/validations';
//import { animateOnScroll } from "../components/animate_on_scroll";
import { encryptAndValidate } from "../components/encrypt_and_validate";
import { validateCompany, onNewCompanySubmit } from "../components/validations/companies/new";
import { statusMidt } from "../components/status_midt";
import { recordReview } from "../components/record_review";
import {onSubmitPersonalInfo} from "../components/validations/after_signup/personal_info";
import {onSubmitUniqueKey} from "../components/validations/after_signup/unique_key";
import {onSubmitContractSet} from "../components/validations/contract_sets/new";
import {onSubmitEnding} from "../components/validations/endings/new";
import {onSubmitAttachment} from "../components/validations/attachments/new";
import {onSubmitContractUploadFormFileUpload} from "../components/validations/contract_upload_form/file_upload";
import {
  onSubmitContractAttachmentUploadFormFileUpload
} from "../components/validations/contract_attachment_upload_form/file_upload";
import {onSubmitContractEndUploadFormFileUpload} from "../components/validations/contract_end_upload_form/file_upload";
import {onCompanySyncing} from "../components/data/internals/record_review"
import {onCompanyInfoCollapse, onShowCompaniesHiddenElements} from "../components/data/companies/show";
import {onAccountContextChange} from "../components/data/internals/account-context";
import {validateCompanies} from "../components/validations/companies_upload/companies_info_upload";
import { onRemunerationBookCompanySyncing } from "../components/data/remuneration_book/record_review";
import { onPenaltyFeeCompanySyncing } from "../components/data/penalty_fee/record_review";
import {initializeValidations} from "../components/validations/config";
import {validateRemunerationBooks} from "../components/validations/remuneration_book_upload";
import {preferences} from "../components/data/internals/preferences";

Chartkick.configure({language: "es"})

document.addEventListener("turbolinks:load", () => {
  // Both of these are from the Bootstrap 5 docs
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new Tooltip(tooltipTriggerEl)
  })

  var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
  var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new Popover(popoverTriggerEl)
  })

  passwordStrength();
  validations();
  //animateOnScroll();
  encryptAndValidate();
  validateCompany();
  onNewCompanySubmit()
  onSubmitPersonalInfo();
  onSubmitUniqueKey();
  onSubmitContractSet();
  onSubmitEnding();
  onSubmitAttachment();
  onSubmitContractUploadFormFileUpload();
  onSubmitContractAttachmentUploadFormFileUpload();
  onSubmitContractEndUploadFormFileUpload();
  onCompanySyncing();
  onCompanyInfoCollapse()
  onShowCompaniesHiddenElements()
  statusMidt();
  recordReview();
  onRemunerationBookCompanySyncing();
  onAccountContextChange()
  onPenaltyFeeCompanySyncing();
  validateCompanies();
  initializeValidations();
  validateRemunerationBooks();
  preferences()
})

// Disable file attachment in ActionText
window.addEventListener("trix-file-accept", function (event) {
  event.preventDefault();
  alert("Archivos adjuntos desactivados!");
})


Rails.start()
Turbolinks.start()
ActiveStorage.start()

require("trix")
require("@rails/actiontext")
