const passwordStrength = () => {
  const passwordInput = document.querySelector("#password");
  const confirmPasswordInput = document.querySelector("#confirm_password");

  const sixCharIcon = document.querySelector("#six_char_icon");
  const upperCaseIcon = document.querySelector("#upper_case_icon");
  const withNumberIcon = document.querySelector("#with_number_icon");
  const specialCharIcon = document.querySelector("#special_char_icon");
  const matchIcon = document.querySelector("#match_icon");

  const submitBtn = document.querySelector(".submit_button");

  const sixChar = new RegExp('(?=.{6,})');
  const upperCase = new RegExp('(?=.*[A-Z])');
  const withNumber = new RegExp('(?=.*[0-9])');
  const specialChar = new RegExp('(?=.*[^A-Za-z0-9])');

  let sixCharStatus = false;
  let upperCaseStatus = false;
  let withNumberStatus = false;
  let specialCharStatus = false;
  let passwordMatchStatus = false;

  if (confirmPasswordInput) {
    passwordInput.addEventListener('input', (event) => {
      const password = event.currentTarget.value;
      if (sixChar.test(password)) {
        sixCharIcon.classList.remove("bx-x-circle");
        sixCharIcon.classList.remove("text-danger");
        sixCharIcon.classList.add("bx-check-circle");
        sixCharIcon.classList.add("text-success");
        sixCharStatus = true;
      } else {
        sixCharIcon.classList.remove("bx-check-circle");
        sixCharIcon.classList.remove("text-success");
        sixCharIcon.classList.add("bx-x-circle");
        sixCharIcon.classList.add("text-danger");
        sixCharStatus = false;
      }

      if (upperCase.test(password)) {
        upperCaseIcon.classList.remove("bx-x-circle");
        upperCaseIcon.classList.remove("text-danger");
        upperCaseIcon.classList.add("bx-check-circle");
        upperCaseIcon.classList.add("text-success");
        upperCaseStatus = true;
      } else {
        upperCaseIcon.classList.remove("bx-check-circle");
        upperCaseIcon.classList.remove("text-success");
        upperCaseIcon.classList.add("bx-x-circle");
        upperCaseIcon.classList.add("text-danger");
        upperCaseStatus = false;
      }

      if (withNumber.test(password)) {
        withNumberIcon.classList.remove("bx-x-circle");
        withNumberIcon.classList.remove("text-danger");
        withNumberIcon.classList.add("bx-check-circle");
        withNumberIcon.classList.add("text-success");
        withNumberStatus = true;
      } else {
        withNumberIcon.classList.remove("bx-check-circle");
        withNumberIcon.classList.remove("text-success");
        withNumberIcon.classList.add("bx-x-circle");
        withNumberIcon.classList.add("text-danger");
        withNumberStatus = false;
      }

      if (specialChar.test(password)) {
        specialCharIcon.classList.remove("bx-x-circle");
        specialCharIcon.classList.remove("text-danger");
        specialCharIcon.classList.add("bx-check-circle");
        specialCharIcon.classList.add("text-success");
        specialCharStatus = true;
      } else {
        specialCharIcon.classList.remove("bx-check-circle");
        specialCharIcon.classList.remove("text-success");
        specialCharIcon.classList.add("bx-x-circle");
        specialCharIcon.classList.add("text-danger");
        specialCharStatus = false;
      }

      passwordMatch();
      submitControl();
    });

    confirmPasswordInput.addEventListener('input', (event) => {
      passwordMatch();
      submitControl();
    });

    const passwordMatch = () => {
      if (passwordInput.value === confirmPasswordInput.value) {
        matchIcon.classList.remove("bx-x-circle");
        matchIcon.classList.remove("text-danger");
        matchIcon.classList.add("bx-check-circle");
        matchIcon.classList.add("text-success");
        passwordMatchStatus = true;
      } else {
        matchIcon.classList.remove("bx-check-circle");
        matchIcon.classList.remove("text-success");
        matchIcon.classList.add("bx-x-circle");
        matchIcon.classList.add("text-danger");
        passwordMatchStatus = false;
      }
    }

    const submitControl = () => {
      if (sixCharStatus && upperCaseStatus && withNumberStatus && specialCharStatus && passwordMatchStatus) {
        submitBtn.disabled = false;
      } else {
        submitBtn.disabled = true;
      }
    }
  }
}

export { passwordStrength };
