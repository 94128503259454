export const onAccountContextChange = () => {
    const filter = document.getElementById('account_context');
    if (filter){
        filter.addEventListener('change', function() {
            const closestForm = this.closest('form');
            if (closestForm) {
                closestForm.submit();
            }
        });
    }


}