import JSEncrypt from 'assets/javascripts/jsencrypt.min.js'
import { validate, clean, format, getCheckDigit } from 'rut.js'

const encryptAndValidate = () => {
  const btn = document.querySelector('#encrypt_and_validate_btn');

  if (btn) {
    btn.addEventListener('click', () => {
      renderResults('init');

      const rut = document.querySelector('#user_rut');
      const uniqueKey = document.querySelector('#user_unique_key');
      const publicKey = `-----BEGIN PUBLIC KEY-----
        MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAqy45MO/Mj9gDJXHhMU0s
        aEfpTMuP1ZmPHnuZdRgGZCgJDKihMHb44w5Ud0PO999G6/nLvZJDCpDHQ6tLDObT
        A8YlBhqYKxPKel/VELYaRGerj45w5IFWdSdwy3T/so/m2hGHmwun5ZJ2TnHXVsiU
        UtqeaV7LCPtaa+27i/CGSuvNr44tQxj5D7hZOMA4tRjuSlala8cdjCCfHIdmQqqq
        T5FTAuJ4FhghFB0md0adtK/lqn05MMl1WdkyRC8zQgzuP0Y2sXihR2Dt1PhNE2QB
        4ZWKKuRsRhsfl/2B5XKxfXfylOLyQnxhc7vZc358bydWY5IHEqUBrf7vEuYSKXM4
        SQIDAQAB
        -----END PUBLIC KEY-----`;

      const encrypt = new JSEncrypt();
      encrypt.setPublicKey(publicKey);
      const uniqueKeyEncrypted = encrypt.encrypt(uniqueKey.value);

      const data = { user: format(rut.value, { dots: false }), password: uniqueKeyEncrypted };

      jQuery.post(`${window.location.origin}/validate/credentials`, data, function (data) {
        if (data.request_id) {
          const requestId = data.request_id;

          let retry = true;
          let i = 0;
          do {
            i = i + 1;
            const getStatus = async () => {
              await sleep(5000 * i);
              jQuery.getJSON(`${window.location.origin}/validate/${requestId}/status`, function (validationResult) {
                if (validationResult.status === 'EXECUTED') {
                  retry = false;
                  if (validationResult.task_summaries[0].status === 'EXECUTED_WITH_SUCCESS') {
                    renderResults('ok');
                  } else if (validationResult.task_summaries[0].status === 'EXECUTED_WITH_ERROR') {
                    renderResults('wrong');
                  } else {
                    renderResults('wrong');
                  }
                }
              })
            };

            getStatus();
          } while (retry && i < 50);

        } else {
          renderResults('wrong');
        }
      })

    });
  }
};

const sleep = (milliseconds) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
}

const renderResults = (step) => {
  const spinner = document.querySelector("#encrypt_and_validate_spinner");
  const okIcon = document.querySelector("#encrypt_and_validate_ok");
  const xIcon = document.querySelector("#encrypt_and_validate_error");
  const message = document.querySelector("#message");
  const submitBtn = document.querySelector(".submit_btn");

  if (step === 'init') {
    submitBtn.disabled = true;
    okIcon.style.display = "none";
    xIcon.style.display = "none";
    spinner.style.display = "";
    message.classList.remove("text-success");
    message.classList.remove("text-danger");
    message.innerHTML = "La validaci&oacute;n podr&iacute;a tomar un minuto."

  } else if (step === 'ok') {
    spinner.style.display = "none";
    okIcon.style.display = "";
    submitBtn.disabled = false;
    message.innerHTML = "Validaci&oacute;n correcta."
    message.classList.add("text-success");

  } else if (step === 'wrong') {
    spinner.style.display = "none";
    xIcon.style.display = "";
    submitBtn.disabled = true;
    message.innerHTML = "Validaci&oacute;n incorrecta."
    message.classList.add("text-danger");
  }
};


export { encryptAndValidate };
